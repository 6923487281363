import React, { useEffect, useRef } from 'react';
import './Intro.css';

function Intro() {
  const mapElement = useRef(null); //지도

  // 지도
  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    const location = new naver.maps.LatLng("37.4826262", "126.9422467");
    const mapOptions = {
      center: location,
      zoom: 17,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };

    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, [])

  return (
    <div className="intro">
      {/* 지도 */}
      <div ref={mapElement} className="intro-map"/>
      <img src="img/intro.png" alt="urbanmix" style={{ objectFit: 'none', alignSelf: 'flex-start' }} />
    </div>
  );
}

export default Intro;
