import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./Home";
import Profile from "./GelleryFile/Profile";
import Body from "./GelleryFile/Body";
import Snap from "./GelleryFile/Snap";
import Animal from "./GelleryFile/Animal";
import Signature from "./GelleryFile/Signature";
import Couple from "./GelleryFile/Couple";
import Family from "./GelleryFile/Family";
import ProfileDetail from "./ProfileDetail";
import Profile1 from "./detail/Profile1";
import Profile2 from "./detail/Profile2";
import Profile3 from "./detail/Profile3";
import Profile4 from "./detail/Profile4";
import Profile5 from "./detail/Profile5";
import Profile6 from "./detail/Profile6";
import Profile7 from "./detail/Profile7";
import Profile8 from "./detail/Profile8";
import Profile9 from "./detail/Profile9";
import Profile10 from "./detail/Profile10";
import Profile11 from "./detail/Profile11";
import Profile12 from "./detail/Profile12";
import Profile13 from "./detail/Profile13";
import Profile14 from "./detail/Profile14";
import Profile15 from "./detail/Profile15";
import Profile16 from "./detail/Profile16";
import Animal1 from "./detail/Animal1";
import Animal2 from "./detail/Animal2";
import Body1 from "./detail/Body1";
import Couple1 from "./detail/Couple1";
import Family1 from "./detail/Family1";
import Signature1 from "./detail/Signature1";
import Signature2 from "./detail/Signature2";
import Snap1 from "./detail/Snap1";
import Snap2 from "./detail/Snap2";
import Intro from "./Intro";
import Price from "./Price";
import Reserve from "./Reserve";

function MainApp() {
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = window.innerWidth <= 768;
  const backgroundImage = isMobile ? '/img/mobile_main.jpg' : '/img/main_1.jpg';

  useEffect(() => { //뒤로가기 구현
    const handleBackButton = (event) => {
      if (event.keyCode === 8) { // 8은 백스페이스 키의 키코드
        event.preventDefault();
        navigate(-1);
      }
    };

    window.addEventListener('keydown', handleBackButton);

    return () => {
      window.removeEventListener('keydown', handleBackButton);
    };
  }, [navigate]);

  return (
    <div className="App">
      {location.pathname === '/' && (
        <div
          className="bg-image"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
      )}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/body" element={<Body />} />
        <Route path="/snap" element={<Snap />} />
        <Route path="/animal" element={<Animal />} />
        <Route path="/signature" element={<Signature />} />
        <Route path="/couple" element={<Couple />} />
        <Route path="/family" element={<Family />} />
        <Route path="/profile_detail" element={<ProfileDetail />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/price" element={<Price />} />
        <Route path="/profile1" element={<Profile1 />} />
        <Route path="/profile2" element={<Profile2 />} />
        <Route path="/profile3" element={<Profile3 />} />
        <Route path="/profile4" element={<Profile4 />} />
        <Route path="/profile5" element={<Profile5 />} />
        <Route path="/profile6" element={<Profile6 />} />
        <Route path="/profile7" element={<Profile7 />} />
        <Route path="/profile8" element={<Profile8 />} />
        <Route path="/profile9" element={<Profile9 />} />
        <Route path="/profile10" element={<Profile10 />} />
        <Route path="/profile11" element={<Profile11 />} />
        <Route path="/profile12" element={<Profile12 />} />
        <Route path="/profile13" element={<Profile13 />} />
        <Route path="/profile14" element={<Profile14 />} />
        <Route path="/profile15" element={<Profile15 />} />
        <Route path="/profile16" element={<Profile16 />} />
        <Route path="/animal1" element={<Animal1 />} />
        <Route path="/animal2" element={<Animal2 />} />
        <Route path="/body1" element={<Body1 />} />
        <Route path="/couple1" element={<Couple1 />} />
        <Route path="/family1" element={<Family1 />} />
        <Route path="/signature1" element={<Signature1 />} />
        <Route path="/signature2" element={<Signature2 />} />
        <Route path="/snap1" element={<Snap1 />} />
        <Route path="/snap2" element={<Snap2 />} />
        <Route path="/reserve" element={<Reserve />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <MainApp />
    </BrowserRouter>
  );
}

export default App;
