import React from "react";
import './Gellery.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Profile() {
  const { t } = useTranslation();

  return (
    <div className="gellery">
      <div className="gellery-tab" >
        <img src="img/메인갤러리.png" alt="gallely" className="gellery-main" />
        <Link to="/signature">Signature Id Picture</Link>
        <strong>Portrait</strong>
        <Link to="/snap">Film</Link>
        <Link to="/body">Body Portrait</Link>
        <Link to="/couple">Couple</Link>
        {/* <Link to="/family">Family</Link> */}
        <Link to="/animal">Animal</Link>
      </div>
      <div className="profile-ex">
        <Link to="/profile1"><img src={t('profile1')} alt="urbanmix" /></Link>
        <Link to="/profile2"><img src={t('profile2')} alt="urbanmix" /></Link>
        <Link to="/profile3"><img src={t('profile3')} alt="urbanmix" /></Link>
        <Link to="/profile4"><img src={t('profile4')} alt="urbanmix" /></Link>
        <Link to="/profile5"><img src={t('profile5')} alt="urbanmix" /></Link>
        <Link to="/profile6"><img src={t('profile6')} alt="urbanmix" /></Link>
        <Link to="/profile7"><img src={t('profile7')} alt="urbanmix" /></Link>
        <Link to="/profile8"><img src={t('profile8')} alt="urbanmix" /></Link>
        <Link to="/profile9"><img src={t('profile9')} alt="urbanmix" /></Link>
        <Link to="/profile10"><img src={t('profile10')} alt="urbanmix" /></Link>
        <Link to="/profile11"><img src={t('profile11')} alt="urbanmix" /></Link>
        <Link to="/profile12"><img src={t('profile12')} alt="urbanmix" /></Link>
        <Link to="/profile13"><img src={t('profile13')} alt="urbanmix" /></Link>
        <Link to="/profile14"><img src={t('profile14')} alt="urbanmix" /></Link>
        <Link to="/profile15"><img src={t('profile15')} alt="urbanmix" /></Link>
        <Link to="/profile16"><img src={t('profile16')} alt="urbanmix" /></Link>
      </div>
    </div>
  );
}

export default Profile;
