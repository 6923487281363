import React from "react";
import './Price.css';
import { useTranslation } from 'react-i18next';

function Price() {
  const { t } = useTranslation();

  return (
    <div className="price">
      <img src={t('price1')} alt="가격표1" style={{ marginTop: '15px' }} />
      <img src={t('price2')} alt="가격표2" />
      <img src={t('price3')} alt="가격표3" />
      <img src={t('price4')} alt="가격표4" />
      <img src={t('price5')} alt="가격표5" />
      <img src={t('price6')} alt="가격표5" />
    </div>
  );
}

export default Price;
