import React, { useEffect } from "react";
import './Detail.css';

function Profile1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="detail">
         <img src="img/detail/profile1_1.jpg" alt="urbanmix" />
    </div>
  );
}

export default Profile1;
