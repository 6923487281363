import React from "react";
import './Gellery.css';
import { Link } from "react-router-dom";

function Profile() {

  return (
    <div className="gellery">
    <div className="gellery-tab" >
        <img src="img/메인갤러리.png" alt="gallely" className="gellery-main" />
        <Link to="/signature">Signature Id Picture</Link>
        <Link to="/profile">Portrait</Link>
        <Link to="/snap">Film</Link>
        <Link to="/body">Body Portrait</Link>
        <Link to="/couple">Couple</Link>
        {/* <strong>Family</strong> */}
        <Link to="/animal">Animal</Link>
      </div>
      <div className="profile-container">
        <Link to="/profile1"><img src="img/GelleryImage/profile_1.png" alt="urbanmix" /></Link>
      </div>
    </div>
  );
}

export default Profile;
