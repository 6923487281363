import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ko: {
        translation: {
          "reserve": "예약하기",
          "reservation": "/img/예약.jpg",
          "price1": "/img/price/price1.jpg",
          "price2": "/img/price/price2.jpg",
          "price3": "/img/price/price3.jpg",
          "price4": "/img/price/price4.jpg",
          "price5": "/img/price/price5.jpg",
          "price6": "/img/price/price6.jpg",
          "signature1": "/img/GelleryImage/signature1.jpg",
          "signature2": "/img/GelleryImage/signature2.jpg",
          "profile1": "/img/GelleryImage/profile1.jpg",
          "profile2": "/img/GelleryImage/profile2.jpg",
          "profile3": "/img/GelleryImage/profile3.jpg",
          "profile4": "/img/GelleryImage/profile4.jpg",
          "profile5": "/img/GelleryImage/profile5.jpg",
          "profile6": "/img/GelleryImage/profile6.jpg",
          "profile7": "/img/GelleryImage/profile7.jpg",
          "profile8": "/img/GelleryImage/profile8.jpg",
          "profile9": "/img/GelleryImage/profile9.jpg",
          "profile10": "/img/GelleryImage/profile10.jpg",
          "profile11": "/img/GelleryImage/profile11.jpg",
          "profile12": "/img/GelleryImage/profile12.jpg",
          "profile13": "/img/GelleryImage/profile13.jpg",
          "profile14": "/img/GelleryImage/profile14.jpg",
          "profile15": "/img/GelleryImage/profile15.jpg",
          "profile16": "/img/GelleryImage/profile16.jpg",
        }
      },
      en: {
        translation: {
          "reserve": "Reservation",
          "reservation": "/img/예약_e.jpg",
          "price1": "/img/price/price1_e.jpg",
          "price2": "/img/price/price2_e.jpg",
          "price3": "/img/price/price3_e.jpg",
          "price4": "/img/price/price4_e.jpg",
          "price5": "/img/price/price5_e.jpg",
          "price6": "/img/price/price6_e.jpg",
          "signature1": "/img/GelleryImage/signature1_e.jpg",
          "signature2": "/img/GelleryImage/signature2_e.jpg",
          "profile1": "/img/GelleryImage/profile1_e.jpg",
          "profile2": "/img/GelleryImage/profile2_e.jpg",
          "profile3": "/img/GelleryImage/profile3_e.jpg",
          "profile4": "/img/GelleryImage/profile4_e.jpg",
          "profile5": "/img/GelleryImage/profile5_e.jpg",
          "profile6": "/img/GelleryImage/profile6_e.jpg",
          "profile7": "/img/GelleryImage/profile7_e.jpg",
          "profile8": "/img/GelleryImage/profile8_e.jpg",
          "profile9": "/img/GelleryImage/profile9_e.jpg",
          "profile10": "/img/GelleryImage/profile10_e.jpg",
          "profile11": "/img/GelleryImage/profile11_e.jpg",
          "profile12": "/img/GelleryImage/profile12_e.jpg",
          "profile13": "/img/GelleryImage/profile13_e.jpg",
          "profile14": "/img/GelleryImage/profile14_e.jpg",
          "profile15": "/img/GelleryImage/profile15_e.jpg",
          "profile16": "/img/GelleryImage/profile16_e.jpg",
        }
      },
      ja: {
        translation: {
          "reserve": "予約する",
          "reservation": "/img/예약_j.jpg",
          "price1": "/img/price/price1_e.jpg",
          "price2": "/img/price/price2_e.jpg",
          "price3": "/img/price/price3_e.jpg",
          "price4": "/img/price/price4_e.jpg",
          "price5": "/img/price/price5_e.jpg",
          "price6": "/img/price/price6_e.jpg",
          "signature1": "/img/GelleryImage/signature1_j.jpg",
          "signature2": "/img/GelleryImage/signature2_j.jpg",
          "profile1": "/img/GelleryImage/profile1_j.jpg",
          "profile2": "/img/GelleryImage/profile2_j.jpg",
          "profile3": "/img/GelleryImage/profile3_j.jpg",
          "profile4": "/img/GelleryImage/profile4_j.jpg",
          "profile5": "/img/GelleryImage/profile5_j.jpg",
          "profile6": "/img/GelleryImage/profile6_j.jpg",
          "profile7": "/img/GelleryImage/profile7_j.jpg",
          "profile8": "/img/GelleryImage/profile8_j.jpg",
          "profile9": "/img/GelleryImage/profile9_j.jpg",
          "profile10": "/img/GelleryImage/profile10_j.jpg",
          "profile11": "/img/GelleryImage/profile11_j.jpg",
          "profile12": "/img/GelleryImage/profile12_j.jpg",
          "profile13": "/img/GelleryImage/profile13_j.jpg",
          "profile14": "/img/GelleryImage/profile14_j.jpg",
          "profile15": "/img/GelleryImage/profile15_j.jpg",
          "profile16": "/img/GelleryImage/profile16_j.jpg",
        }
      }
    },
    lng: "ko", // 기본 언어 설정 (한국어로 설정)
    fallbackLng: "ko", // 번역이 없을 경우 대비 언어 (한국어로 설정)

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;